<template>
  <div>
    <div class="md-layout-item md-small-size-10.0 md-size-50">
      <md-field>
        <label>{{ $t('label.select_iri_file') }}</label>
        <md-file v-model="fileName.name" @md-change="onFileUpload($event)" />
      </md-field>
    </div>
    <img v-if="imageUrl" :src="imageUrl" alt="Protected Image" />
    <img
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6MEVBMTczNDg3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6MEVBMTczNDk3QzA5MTFFNjk3ODM5NjQyRjE2RjA3QTkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDowRUExNzM0NjdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDowRUExNzM0NzdDMDkxMUU2OTc4Mzk2NDJGMTZGMDdBOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjjUmssAAAGASURBVHjatJaxTsMwEIbpIzDA6FaMMPYJkDKzVYU+QFeEGPIKfYU8AETkCYI6wANkZQwIKRNDB1hA0Jrf0rk6WXZ8BvWkb4kv99vn89kDrfVexBSYgVNwDA7AN+jAK3gEd+AlGMGIBFDgFvzouK3JV/lihQTOwLtOtw9wIRG5pJn91Tbgqk9kSk7GViADrTD4HCyZ0NQnomi51sb0fUyCMQEbp2WpU67IjfNjwcYyoUDhjJVcZBjYBy40j4wXgaobWoe8Z6Y80CJBwFpunepIzt2AUgFjtXXshNXjVmMh+K+zzp/CMs0CqeuzrxSRpbOKfdCkiMTS1VBQ41uxMyQR2qbrXiiwYN3ACh1FDmsdK2Eu4J6Tlo31dYVtCY88h5ELZIJJ+IRMzBHfyJINrigNkt5VsRiub9nXICdsYyVd2NcVvA3ScE5t2rb5JuEeyZnAhmLt9NK63vX1O5Pe8XaPSuGq1uTrfUgMEp9EJ+CQvr+BJ/AAKvAcCiAR+bf9CjAAluzmdX4AEIIAAAAASUVORK5CYII=">
    <img :src="imageUrl1" />
  </div>
</template>
  
<script>
  export default {
    data() {
      return {
        fileName: { name: '', },
        importFile: null,
        imageUrl1: null,
        image: null
      }
    },

    created() {
      this.fetchFile()
    },

    methods: {
      onFileUpload(evt) {
        this.importFile = evt[0]
        console.log(this.importFile)

        const reader = new FileReader()
        reader.onload = (evt) => {
          this.image = evt.target.result;
        }
        reader.onerror = (err) => console.log(err);
        reader.readAsDataURL(this.importFile);

        this.$store.dispatch('READ_IMAGE_FILES').then((res) => {
          console.log("img:", res)
          this.imageUrl1 = res;
        })
        return
      },

      // works only for paths accessible by web-server
      fetchWebServerFile() {
        const fileName = "/0000001.jpg"

        var result = null;
        var xmlhttp = new XMLHttpRequest();
        xmlhttp.open("GET", fileName, false);
        xmlhttp.send();
        if (xmlhttp.status == 200) {
          result = xmlhttp.responseText;
        }
        return result;
      },

      fetchFile() {
        // Use API provided on the server side
        const fileName = "A09.png"
        this.$store.dispatch('READ_SERVER_IMAGE_FILES', fileName).then((res) => {
          console.log("img:", res)
          this.imageUrl1 = res;
        })
      },
    },
    computed: {
      imageUrl() {
        const base64String = `data:image/png;charset=utf-8;base64, ${btoa(this.image)}`
        return this.image

      }
    }
  }
</script>
  